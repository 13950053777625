import request from '@/utils/request'

export function fetchBest10Reports(query) {
  return request({
    url: '/v1/analysis/best10/reports',
    method: 'get',
    params: query
  })
}

export function fetchBest10Recently(query) {
  return request({
    url: '/v1/analysis/best10/recently-best',
    method: 'get',
    params: query
  })
}

export function fetchBest20Image(query) {
  return request({
    url: '/v1/analysis/best20/images',
    method: 'get',
    params: query
  })
}

export function fetchTPSReports(query) {
  return request({
    url: '/v1/analysis/tps/reports',
    method: 'get',
    params: query
  })
}

export function fetchTPSItemReports(query) {
  return request({
    url: '/v1/analysis/tps/reports/item-brand',
    method: 'get',
    params: query
  })
}

export function fetchMatrixReports(query) {
  return request({
    url: '/v1/analysis/matrix/reports',
    method: 'get',
    params: query
  })
}

export function exportBest10Reports(query) {
  return request({
    url: '/v1/analysis/best10/export',
    method: 'get',
    params: query
  })
}

export function exportTPSReports(query) {
  return request({
    url: '/v1/analysis/tps/export',
    method: 'get',
    params: query
  })
}

export function exportTPSItemReports(query) {
  return request({
    url: '/v1/analysis/tps/item-brand/export',
    method: 'get',
    params: query
  })
}

export function exportMatrixReports(query) {
  return request({
    url: '/v1/analysis/matrix/export',
    method: 'get',
    params: query
  })
}

export function deleteReport(type, id) {
  let url = '/v1/analysis/best10/reports/'
  if (type === 'TPS') {
    url = '/v1/analysis/tps/reports/'
  } else if (type === 'Matrix') {
    url = '/v1/analysis/matrix/reports/'
  }
  return request({
    url: url + id,
    method: 'delete'
  })
}

export function fetchUserBrands(query) {
  return request({
    url: '/v1/analysis/user-brands',
    method: 'get',
    params: query
  })
}

export function fetchUserItems(query) {
  return request({
    url: '/v1/analysis/user-items',
    method: 'get',
    params: query
  })
}

export function fetchUserBrandType() {
  return request({
    url: '/v1/analysis/user-brand-types',
    method: 'get',
  })
}

export function fetchRecords(query) {
  return request({
    url: '/v1/analysis/records',
    method: 'get',
    params: query
  })
}

export function fetchBasicReport(query) {
  return request({
    url: '/v1/analysis/basic/reports',
    method: 'get',
    params: query
  })
}

export function exportBasicReport(type, params) {
  return request({
    url: '/v1/analysis/basic/' + type + '/export',
    method: 'get',
    params: params
  })
}

export function fetchBasicPrimary(type, params) {
  return request({
    url: '/v1/analysis/basic/' + type + '/primary',
    method: 'get',
    params: params
  })
}

export function fetchItemSales(query) {
  return request({
    url: '/v1/analysis/item-sales',
    method: 'get',
    params: query
  })
}

export function fetchHotStar(query) {
  return request({
    url: '/v1/analysis/weekly-hot-star',
    method: 'get',
    params: query
  })
}

export function fetchNewProduct(query) {
  return request({
    url: '/v1/analysis/new-product',
    method: 'get',
    params: query
  })
}

export function fetchBest30SpecificItem(query) {
  return request({
    url: '/v1/analysis/specific-item/item',
    method: 'get',
    params: query
  })
}

export function fetchBest30(query) {
  return request({
    url: '/v1/analysis/specific-item',
    method: 'get',
    params: query
  })
}

export function exportBest30Report(query) {
  return request({
    url: '/v1/analysis/specific-item/export',
    method: 'get',
    params: query
  })
}

export function fetchPrimaryTps(query) {
  return request({
    url: '/v1/analysis/tps/primary',
    method: 'get',
    params: query
  })
}

export function fetchPrimaryMatrix(query) {
  return request({
    url: '/v1/analysis/matrix/primary',
    method: 'get',
    params: query
  })
}

export function fetchDetailValues(query) {
  return request({
    url: '/v1/analysis/detail/values',
    method: 'get',
    params: query
  })
}

export function fetchDetail(query) {
  return request({
    url: '/v1/analysis/detail',
    method: 'get',
    params: query
  })
}

export function fetchSalesTrend(query) {
  return request({
    url: '/v1/analysis/sales-trend',
    method: 'get',
    params: query
  })
}

export function fetchWeeklySalesTrend(query) {
  return request({
    url: '/v1/analysis/weekly-sales-trend',
    method: 'get',
    params: query
  })
}

export function fetchProductInfo(query) {
  return request({
    url: '/v1/analysis/product/info',
    method: 'get',
    params: query
  })
}

export function fetchProductSalePercentage(query) {
  return request({
    url: '/v1/analysis/product/sales-percentage',
    method: 'get',
    params: query
  })
}

export function fetchProductLast12WeekDetail(query) {
  return request({
    url: '/v1/analysis/product/last-12-week-details',
    method: 'get',
    params: query
  })
}

export function fetchProductBestKey() {
  return request({
    url: '/v1/analysis/product/best-sales-key',
    method: 'get'
  })
}

export function fetchImageTrendMapItems(query) {
  return request({
    url: '/v1/analysis/image-trend-map/items',
    method: 'get',
    params: query
  })
}

export function fetchImageTrendMap(query) {
  return request({
    url: '/v1/analysis/image-trend-map',
    method: 'get',
    params: query
  })
}
