import request from '@/utils/request'

export function fetchCategory(product) {
  return request({
    url: '/v1/report/' + product + '/category',
    method: 'get',
  })
}

export function fetchItem(product, query) {
  return request({
    url: '/v1/report/' + product + '/item',
    method: 'get',
    params: query
  })
}

export function fetchPriceReportData(query) {
  return request({
    url: '/v1/report/price',
    method: 'get',
    params: query
  })
}

export function fetchItemReportData(query) {
  return request({
    url: '/v1/report/item',
    method: 'get',
    params: query
  })
}

export function fetchColorReportData(query) {
  return request({
    url: '/v1/report/color',
    method: 'get',
    params: query
  })
}

export function fetchTextileReportData(query) {
  return request({
    url: '/v1/report/textile',
    method: 'get',
    params: query
  })
}